import React, { useEffect, useState } from "react";
import Banner from "../components/banner";

import NewsLetter from "../components/newsletter";
import MenuFooter from "../components/menu_footer";
import Politicas from "../components/politicas";
import Redes from "../components/redes";
import Copyright from "../components/copyright";

import $ from 'jquery';

import BannerMobile from "../components/banner_mobile";

import CardContacto from "../components/card_contacto";

import ComponenteCompra from "../components/componente_compra";
import PaginatedItems from "../components/lista_card_blog";
import CardContactoMobile from "../components/card_contacto_mobile";
import * as app from "../fetch/fetchApi";


const PlantillaListaBlog = () => {
  const isBrowser = typeof window !== "undefined"
  let width;
  /**
   let datos = this.state.data;
          let datosInstagram = this.state.dataInstagram;
          let datosEventos = this.state.dataEvento
          let datosMenu = this.state.dataMenu
          let datosNodo = this.state.dataNodo
          let datosComplementa = this.state.dataComplementa
          let datosMenuFooter = this.state.dataMenuFooter
          let datosPrecios = this.state.dataPrecio
          let datosBlog=this.state.dataBlog
          let datosTarifas = this.state.dataTarifas
   */


  const [datosMenu, setDataMenu] = useState({})

  useEffect(() => {
    // get data from GitHub api

    fetch(app.url + `/node-api/m22_item_menu/main-menu`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenu(resultData)
      })
    // set data for the number of stars
  }, [])
  const [datosNodo, setDataNodo] = useState([])
  const [datosPrecios, setDataPrecios] = useState([])
  const [datosTarifas, setDataTarifa] = useState([])

  useEffect(() => {

    // get data from GitHub api
    if (isBrowser) {
      console.log(window.location.pathname)
      let id = window.location.pathname
      id = id.substring(1);
      var data = { 'path': id }
    }
    fetch(app.url + "/node-api/m22_get_contenido_node/2157", {
      method: 'GET',
      headers: { 'Content-type': 'application/json' },
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataNodo(resultData)

      })
    // set data for the number of stars
  }, [])
  const [datosComplementa, setDataComplementa] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/views/prueba_get_interior_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataComplementa(resultData)
      })
    // set data for the number of stars
  }, [])
  const [datosMenuFooter, setDataMenuFooter] = useState({})
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/m22_item_menu/menu-foooter-menu-2019`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataMenuFooter(resultData)
      })
    // set data for the number of stars
  }, [])


  const [datosBlogCard, setDataBlogCard] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/views/prueba_get_card_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBlogCard(resultData)
      })
    // set data for the number of stars
  }, [])

  const [datosBlog, setDataBlog] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/views/prueba_lista_blog?display_id=services_1&format_output=0`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataBlog(resultData)
      })
    // set data for the number of stars
  }, [])


  const [datosProductos, setDataProductos] = useState([])
  useEffect(() => {
    // get data from GitHub api
    fetch(app.url + `/node-api/views/prueba_get_product_content/?display_id=services_1&format_output=0&args[0]=129`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData)
        setDataProductos(resultData)
      })
    // set data for the number of stars
  }, [])

  if (isBrowser) {
    $('#inputBusqueda').on('keypress', function (e) {
      if (e.which === 13) {

        console.log($('#inputBusqueda').val())
        //Disable textbox to prevent multiple submit
        //useEffect(()=> {
        setDataBlog([])
        if ($('#inputBusqueda').val() == '') {
          fetch(app.url + `/node-api/views/prueba_lista_blog?display_id=services_1&format_output=0`)
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
              console.log(resultData)
              setDataBlog(resultData)
            })
        } else {
          fetch(app.url + `/node-api/views/prueba_get_card_blog_by_titulo?display_id=services_1&format_output=0&title=` + $('#inputBusqueda').val())
            .then(response => response.json()) // parse JSON from request
            .then(resultData => {
              console.log(resultData)
              setDataBlog(resultData)
            })
          //}, [datosBlog])
          console.log('enter')
        }
      }
    });

    width = window.innerWidth
    console.log(width)
  }
  var contenido = datosBlog;
  var url = datosNodo.field_multimedia !== undefined ? datosNodo.field_multimedia.und[0].uri : '';
  //url=url.replace('public://', '')
  console.log(url)
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];




  return (

    <div>
      {width < 1100 ? <Banner data={datosMenu} /> : <BannerMobile data={datosMenu} />
      }
      <div className="row justify-content-xl-between justify-content-center removeMax px-xl-5 px-md-3 px-2 mb-5" style={{ marginTop: '100px' }}>
        <div className="col-xl-7 mt-md-3 mt-0 col-12 mb-3">
          {console.log(datosBlog)}
          <h1 className="tituloInteres text-center mt-md-5 mt-0"> Blog</h1>
          <div className="me-3" style={{ marginTop: '56px' }}>
            <input type={'text'} id={'inputBusqueda'} className={'inputBusquedaBlog'} placeholder={'Buscar'} />
          </div>
          <div className="mt-5">
            {datosBlog.length > 0 ? <PaginatedItems itemsPerPage={5} data={datosBlog} /> : ''}

          </div>
        </div>
        <div className="col-xl-3 col-12 mt-xl-5 mb-5 mt-0">
          <div className="d-block d-md-none d-xl-block mt-5">
            <CardContacto />
          </div>
          <div className="mt-0 d-none d-md-block d-xl-none">
            <CardContactoMobile />
          </div>

          {datosProductos.length !== 0 ? <h3 className={'letrasRojas mb-4 text-sm-center text-xl-start topMargin  px-4 px-md-0'}>Estamos listos para recibirte</h3> : ''}
          {datosProductos.length !== 0 ? <ComponenteCompra data={datosProductos} /> : ''}
        </div>


      </div>


      <footer className={'l-footer panel mt-4'} role={'contentinfo'}>
        <div className={'footer'}>
          <NewsLetter />
          <MenuFooter data={datosMenuFooter} />

          <Politicas />
          <Redes />
        </div>
        <Copyright />
      </footer>
    </div>
  );
};

export default PlantillaListaBlog;